"use client";

import { handleAppDownload } from "@/lib/download-platform";

import { Button } from "@acme/ui/components/ui/button";

export default function WaitListButton() {
  //   const { setShowModal } = useDownloadStore();

  return (
    <Button onClick={handleAppDownload} className="">
      <span className="mr-1 font-bold">Download now</span> — it's free
    </Button>
  );
}
