import { UAParser } from 'ua-parser-js'


interface AppStoreUrls {
	ios: string
	android: string
	fallback: string
}

export const STORE_URLS: AppStoreUrls = {
	ios: 'https://apps.apple.com/app/cohub42/id6639620555',
	android: 'https://play.google.com/store/apps/details?id=com.taro97.cohub42',
	fallback: '/download-options'
}

export function getPlatformDownloadUrl(userAgent: string): string {
	const parser = new UAParser(userAgent)
	const os = parser.getOS().name?.toLowerCase() || ''

	if (os.includes('ios') || os.includes('mac')) return STORE_URLS.ios
	if (os.includes('android')) return STORE_URLS.android
	return STORE_URLS.fallback
}

export function handleAppDownload() {
	if (typeof window === 'undefined') return

	const downloadUrl = getPlatformDownloadUrl(window.navigator.userAgent)
	window.open(downloadUrl, '_blank')
}